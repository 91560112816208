<template>
	<div id="appMain">
		<web-header></web-header>
		<div class="header">
			<div class="header_l">课后练习</div>
			<div class="header_r" @click="handleSubmit">提交</div>
		</div>
		<div class="container">
			<!-- 考题 -->
			<div class="exam_questions">
				<!-- 单选题 -->
				<div class="exam_type" v-if="radioExerciseList.length !== 0">
					<div class="title">单项选项题</div>
					<div class="topic_item" v-for="(item, index) in radioExerciseList" :key="index">
						<div class="topic_title">
							{{ index + 1 }}.{{ item.examName }}
						</div>
						<el-radio-group v-model="item.stuAnswer">
							<el-radio v-for="(jtem, jndex) in item.examOptions" :key="jndex" :value="jtem.idStr"
								:label="jtem.idStr">{{ jtem.idStr }}.{{ jtem.item }}</el-radio>
						</el-radio-group>
					</div>
				</div>
				<!-- 多选题 -->
				<div class="exam_type" v-if="multipleExerciseList.length !== 0">
					<div class="title">多项选项题</div>
					<div class="topic_item" v-for="(item, index) in multipleExerciseList" :key="index">
						<div class="topic_title">
							{{ index + 1 }}.{{ item.examName }}
						</div>
						<el-checkbox-group v-model="item.checkList" @change="handleCheckboxChange(index,...arguments)">
							<el-checkbox v-for="(jtem, jndex) in item.examOptions" :key="jndex" :label="jtem.idStr"
								:value="jtem.item">
								{{ jtem.idStr }}.{{ jtem.item }}
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<!-- 判断题 -->
				<div class="exam_type" v-if="judgeExerciseList.length !== 0">
					<div class="title">判断题</div>
					<div class="topic_item" v-for="(item, index) in judgeExerciseList" :key="index">
						<div class="topic_title">
							{{ index + 1 }}.{{ item.examName }}
						</div>
						<el-radio-group v-model="item.stuAnswer">
							<el-radio label="对">对</el-radio>
							<el-radio label="错">错</el-radio>
						</el-radio-group>
					</div>
				</div>
			</div>
			<!-- 答题卡 -->
			<div class="answer_sheet">
				<div class="title">答题卡({{answersTotal}}/{{allExerciseList.length}})</div>
				<!-- 单选题 -->
				<div class="question_type" v-if="radioExerciseList.length != 0">
					<div class="type">单项选择题</div>
					<div class="cube_list">
						<div :class="['cube',{'cube_active':item.stuAnswer}]" v-for="(item,index) in radioExerciseList"
							:key="index">{{index+1}}</div>
					</div>
				</div>
				<!-- 多选题 -->
				<div class="question_type" v-if="multipleExerciseList.length != 0">
					<div class="type">多项选择题</div>
					<div class="cube_list">
						<div :class="['cube',{'cube_active':item.stuAnswer}]"
							v-for="(item,index) in multipleExerciseList" :key="index">{{index+1}}</div>
					</div>
				</div>
				<!-- 判断题 -->
				<div class="question_type" v-if="judgeExerciseList.length != 0">
					<div class="type">判断题</div>
					<div class="cube_list">
						<div :class="['cube',{'cube_active':item.stuAnswer}]" v-for="(item,index) in judgeExerciseList"
							:key="index">{{index+1}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import webHeader from '../../components/webHeader.vue';
	export default {
		components: {
			webHeader
		},
		data() {
			return {
				chapterId: '', //章节ID
				courseId: '', //课程ID
				allExerciseList: [], //全部题目
				radioExerciseList: [], //单选题
				multipleExerciseList: [], //多选题
				judgeExerciseList: [], //判断题
				again: 0
			}
		},
		created() {
			this.chapterId = localStorage.getItem('zjieId')
			this.courseId = localStorage.getItem('coureId')
			this._getAfterClassExercise()
			this.again = this.$route.query.again

		},
		computed: {
			answersTotal() {
				let radio = 0;
				let multiple = 0;
				let judge = 0;
				this.radioExerciseList.forEach(item => {
					if (item.stuAnswer) {
						radio++
					}
				})
				this.multipleExerciseList.forEach(item => {
					if (item.stuAnswer) {
						multiple++
					}
				})
				this.judgeExerciseList.forEach(item => {
					if (item.stuAnswer) {
						judge++
					}
				})
				return radio + multiple + judge

			}

		},
		methods: {
			// 选项排序
			compare(propertyName) {
				return function(object1, object2) {
					var value1 = object1[propertyName];
					var value2 = object2[propertyName];
					if (value2 < value1) {
						return 1;
					} else if (value2 > value1) {
						return -1;
					} else {
						return 0;
					}
				};
			},
			// 多选题选中
			handleCheckboxChange(i, e) {
				let sortArr = e.sort();
				let str = '';
				for (let i of sortArr) {
					str += i;
				}
				this.multipleExerciseList[i].stuAnswer = str;
			},
			// 获取试题
			_getAfterClassExercise() {
				this.$http.post('mineTest', {
					chapterId: this.chapterId,
					courseId: this.courseId
				}).then(res => {
					if (res.code == 200) {
						let data = res.data;
						if (this.again) {
							data.radioList && data.radioList.forEach(item => {
								item.stuAnswer = ''
							})
							data.multipleList && data.multipleList.forEach(item => {
								item.stuAnswer = ''
							})
							data.judgeList && data.judgeList.forEach(item => {
								item.stuAnswer = ''
							})
						}
						let allExerciseList = [];
						Object.keys(data).forEach(i => {
							if (data[i]) {

								allExerciseList.push(...data[i]);

							}

							switch (i) {
								case 'radioList':
									let radioArr = []
									if(data['radioList']){
										data['radioList'].forEach(ele => {
										ele.examOptions.sort(this.compare('idStr'))
										radioArr.push(ele)
									});
									this.radioExerciseList = radioArr
									}
									break;
								case 'multipleList':
									let multipleArr = []
									if (data['multipleList']) {
										data['multipleList'].forEach(ele => {
											ele.examOptions.sort(this.compare('idStr'))
											let checkList = [];
											if (ele.stuAnswer) {
												let answerList = ele.stuAnswer.split("");
												for (let i in ele.examOptions) {
													for (let j in answerList) {
														if (answerList[j] == ele.examOptions[i]
															.idStr) {
															checkList.push(ele.examOptions[i]
																.idStr);
														}
													}
												}
											}
											ele.checkList = checkList
											multipleArr.push(ele)
										});
										return this.multipleExerciseList = multipleArr
									}
									break;
								case 'judgeList':

									if (data['judgeList']) {

										return this.judgeExerciseList = data['judgeList']
									}

									break;
							}
						})

						this.allExerciseList = allExerciseList;

					}
				})
			},
			// 提交答题
			handleSubmit() {

				let msg = '是否确认提交?';
				if (this.answersTotal != this.allExerciseList.length) {
					msg = `您还有${this.allExerciseList.length - this.answersTotal}道题未作答，是否确认提交？`
				}
				this.$confirm(msg, '温馨提醒', {
					confirmButtonText: '确认交卷',
					cancelButtonText: '取消',
					type: 'warning',
					showClose: false,
					closeOnClickModal: false,
					center: true
				}).then(() => {
					let replyList = [...this.radioExerciseList, ...this.multipleExerciseList, ...this
						.judgeExerciseList
					];
					let data = replyList.map(item => {
						return {
							answer: item.stuAnswer,
							examId: item.id
						}
					})
					this.$http.post2('mineSave', {
						examList: data,
						chapterId: this.chapterId,
						courseId: this.courseId
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('交卷成功')
							this.$router.push('/examination/answerSituation')
						} else {
							this.$message.error(res.message)
						}
					})
				}).catch(() => {

				});
			},

		}


	}
</script>

<style lang="less" scoped>
	#appMain {
		height: 100%;
		overflow: auto;
	}

	.header {
		height: 94px;
		background: #FFFFFF;
		box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.05);
		padding: 0 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.header_l {
			font-size: 30px;
			color: #111111;
		}

		.header_r {
			width: 227px;
			height: 65px;
			line-height: 65px;
			text-align: center;
			font-size: 24px;
			font-weight: 500;
			color: #FFFFFF;
			background: #E51E0B;
			cursor: pointer;
		}
	}

	.container {
		display: flex;
		justify-content: space-between;
		padding: 42px 47px 0 43px;

	}

	.exam_questions {
		flex: 10;

		// overflow-y: auto;
		// height: calc(70vh);
		.exam_type {
			padding-bottom: 30px;

			.title {
				font-size: 24px;
				color: #333333;
				height: 47px;
				line-height: 47px;
				padding: 0 20px;
				background-color: #dcdcdc;
			}

			.topic_item {
				.topic_title {
					font-size: 18px;
					font-weight: 500;
					color: #333333;
					line-height: 35px;
					padding: 0 20px;
					background-color: #dcdcdc;
					margin-top: 20px;
					margin-left: 15px;
				}
			}
		}
	}

	.answer_sheet {
		flex: 3;
		margin-left: 42px;

		.title {
			width: 227px;
			height: 65px;
			line-height: 65px;
			text-align: center;
			background: #041366;
			font-size: 24px;
			font-weight: 500;
			color: #FFFFFF;
		}

		.question_type {
			padding-left: 31px;
			padding-top: 35px;

			.type {
				font-size: 24px;
				color: #333333;
			}

			.cube_list {
				display: flex;
				flex-wrap: wrap;

				.cube {
					width: 50px;
					height: 50px;
					line-height: 50px;
					border: 1px solid #DCDCDC;
					text-align: center;
					font-size: 22px;
					color: #333333;
					margin-right: 6px;
					margin-top: 8px;
				}

				.cube_active {
					background: #E3E3E3;
				}
			}

		}
	}

	.el-radio-group {
		display: flex;
		flex-direction: column;
		margin-left: 30px;

		.el-radio {
			margin-top: 10px;
		}
	}

	.el-checkbox-group {
		display: flex;
		flex-direction: column;
		margin-left: 30px;

		.el-checkbox {
			margin-top: 10px;
			margin-left: 0;
		}
	}
</style>
